import { queryKey, useDateTransformerFactory, type Optional } from '@segunosoftware/equinox';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import type { BaseBillableAccount, Job } from '../../lib/shared-types';
import {
	MARKETING_PLATFORM_ACCOUNT,
	MARKETING_PLATFORM_ACCOUNT_APP_PURCHASES,
	MARKETING_PLATFORM_ACCOUNT_BILLING_DATE,
	MARKETING_PLATFORM_ACCOUNT_FACEBOOK_DETAILS,
	MARKETING_PLATFORM_ACCOUNT_JOBS,
	MARKETING_PLATFORM_ACCOUNT_KLAVIYO_DETAILS,
	MARKETING_PLATFORM_ACCOUNT_MAILCHIMP_DETAILS,
	MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS,
	MARKETING_PLATFORM_ACCOUNT_SUPPRESSION_TAGS,
	MARKETING_PLATFORM_MANUAL_JOBS,
	MARKETING_PLATFORM_MANUAL_JOB_DETAILS
} from '../query-keys';
import type { Delete, Get, Post } from '../types';
import { useAuthenticatedFetch } from '../useAuthenticatedFetch';

export type BillingPlan = 'LEGACY' | 'STANDARD' | 'ADVANCED';

export type ComplianceHold =
	| 'NONE'
	| 'SUBSCRIBER_IMPORT'
	| 'AWAITING_SURVEY'
	| 'PENDING_REVIEW'
	| 'AWAITING_CUSTOMER'
	| 'UNUSUAL_ACTIVITY'
	| 'UNTRUSTED_CONTENT';

export type ListCleaningLevel = 'ALL' | 'ALL_EXCEPT_ROLE' | 'NONE';

export type AccountBillingStatus = 'unbilled' | 'active' | 'frozen' | 'cancelled';

export type ReviewServiceType = 'NONE' | 'SHOPIFY' | 'JUDGEME' | 'STAMPED' | 'YOTPO' | 'JUNIP' | 'LOOX' | 'FERA';

export type ConnectionType = 'FACEBOOK' | 'INSTAGRAM' | 'MAILCHIMP' | 'KLAVIYO' | 'POSTSCRIPT' | 'REVIEW_SERVICE';

export type ShopifyReview = {
	id: string;
	company: string;
	rating: number;
	date: string;
	comments: string;
};

export type CsmNoteStatus = 'success' | 'info' | 'warning' | 'critical';

export type CsmNote = {
	title: string;
	note: string;
	status: CsmNoteStatus;
	dismissedAt?: Date;
};

export type MarketingPlatformAccount = BaseBillableAccount & {
	currency: string;
	complianceHold: boolean;
	trusted: boolean;
	risky: boolean;
	suspicious: boolean;
	trustedImporter: boolean;
	complianceHoldStatus: ComplianceHold;
	sendingDisabledFromPlan: boolean;
	allowSendingOverride: boolean;
	mailChimpConnected: boolean;
	klaviyoConnected: boolean;
	agreedToCleanList: boolean;
	listCleaningComplete: boolean;
	listCleaningLevel: ListCleaningLevel;
	suppressionTags: string[];
	trialRestarted: boolean;
	backfillComplete: boolean;
	backfillPercentComplete: number;
	ipPool: number;
	sendingDomain: string;
	sendingDomainWarmed: boolean;
	allowSendingWhileWarming: boolean;
	billedSubscribers: number;
	billingPlan: BillingPlan;
	freeTierCap: number;
	billingUnit: number;
	includedBillingUnits: number;
	pricePerUnitInDollars: number;
	basePriceInDollars: number;
	monthlyPrice: number;
	appPurchaseCredits: number;
	espAccountId: string;
	espApiKey: string;
	testEmails: string[];
	fromEmail: string;
	newShop: boolean;
	fastInstall: boolean;
	shouldRecordScreen: boolean;
	shopifyReviewServiceType: ReviewServiceType;
	facebookConnected: boolean;
	facebookExternalId?: string;
	hubspotId?: string;
	instagramConnected: boolean;
	postscriptConnected: boolean;
	replyEmail: string;
	csmNote?: CsmNote;
	supportsResourceFeedback: boolean;
	languageToUnsubscribeText: Record<string, string>;
	trialEndsAt?: Date;
	billingGracePeriodEndsAt?: Date;
	shopCreatedAt: Date;
	createdAt: Date;
	updatedAt: Date;
	customerHealthIndex?: number;
};

export function useUpdateAccount(id: string | number) {
	const queryClient = useQueryClient();
	return (account: MarketingPlatformAccount) => queryClient.setQueryData(queryKey(MARKETING_PLATFORM_ACCOUNT, id), account);
}

export function useMarketingPlatformAccount(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<MarketingPlatformAccount>;
	const { data: account } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT, id),
		select: useDateTransformerFactory('trialEndsAt', 'billingGracePeriodEndsAt', 'shopCreatedAt', 'dismissedAt', 'updatedAt'),
		queryFn: () => get(`/marketing-platform-accounts/${id}`)
	});
	const { jobs, onLoadJobs, isJobsLoading } = useMarketingPlatformJobs(id);
	const { appPurchases, isAppPurchasesLoading } = useMarketingPlatformPurchases(id);

	const billingDate = useMarketingPlatformBillingDate(id);

	return {
		account,
		jobs,
		onLoadJobs,
		isJobsLoading,
		appPurchases:
			appPurchases?.sort((a, b) =>
				b.billingActivatedAt && a.billingActivatedAt ? b.billingActivatedAt.getTime() - a.billingActivatedAt.getTime() : 0
			) ?? [],
		isAppPurchasesLoading,
		billingDate
	};
}

function useMarketingPlatformJobs(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<Job[]>;
	const {
		data: jobs,
		refetch: onLoadJobs,
		isFetching: isJobsLoading
	} = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_JOBS, id),
		queryFn: () => get(`/marketing-platform-accounts/${id}/jobs`),
		select: useDateTransformerFactory('previousFireTime', 'nextFireTime')
	});

	return {
		jobs,
		onLoadJobs,
		isJobsLoading
	};
}

export type AppPurchaseType = 'TEMPLATE' | 'GROUP';
export type AppPurchaseBillingStatus = 'PENDING' | 'ACTIVE';

export type AppPurchase = {
	purchaseType: AppPurchaseType;
	purchaseIdentifier: string;
	price: number;
	billingStatus: AppPurchaseBillingStatus;
	billingActivatedAt?: Date;
};

function useMarketingPlatformPurchases(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<AppPurchase[]>;
	const { data: appPurchases, isFetching: isAppPurchasesLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_APP_PURCHASES, id),
		queryFn: () => get(`/marketing-platform-accounts/${id}/app-purchases`),
		select: useDateTransformerFactory('billingActivatedAt')
	});
	return { appPurchases, isAppPurchasesLoading };
}

function useMarketingPlatformBillingDate(id: string | number): Optional<Date> {
	const { get } = useAuthenticatedFetch() as Get<Date>;
	const transformDate = useDateTransformerFactory('billingDate');
	const { data } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_BILLING_DATE, id),
		queryFn: () => get(`/marketing-platform-accounts/${id}/billing-date`),
		select: data => transformDate({ billingDate: data }) as { billingDate: Date }
	});

	return data?.billingDate;
}

export type MemberListStats = {
	memberCount: number;
	unsubscribeCount: number;
	cleanedCount: number;
	memberCountSinceSend: number;
	unsubscribeCountSinceSend: number;
	cleanedCountSinceSend: number;
	campaignCount: number;
	mergeFieldCount: number;
	avgSubRate: number;
	avgUnsubRate: number;
	targetSubRate: number;
	openRate: number;
	clickRate: number;
	campaignLastSent?: Date;
	lastSubDate?: Date;
	lastUnsubDate?: Date;
};

export type MemberListVisibility = 'pub' | 'prv';

export type MailchimpMemberList = {
	webId: string;
	name: string;
	dateCreated: Date;
	listRating: number;
	emailTypeOption: boolean;
	doubleOptin: boolean;
	notifyOnSubscribe: string;
	notifyOnUnsubscribe: string;
	hasWelcome: boolean;
	marketingPermissions: boolean;
	stats: MemberListStats;
	visibility: MemberListVisibility;
};

export function useMarketingPlatformMailchimpDetails(id: string | number) {
	const { account } = useMarketingPlatformAccount(id);
	const { get } = useAuthenticatedFetch() as Get<MailchimpMemberList>;
	const { data: mailchimpDetails, isFetching: isMailchimpDetailsLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_MAILCHIMP_DETAILS, id),
		queryFn: () => get(`/marketing-platform-accounts/${id}/mailchimp-details`),
		select: useDateTransformerFactory('campaignLastSent', 'lastSubDate', 'lastUnsubDate', 'dateCreated'),
		enabled: account?.mailChimpConnected
	});
	return { mailchimpDetails, isMailchimpDetailsLoading };
}

export type KlaviyoConnectionDetails = {
	siteId: string;
	apiKey: string;
	listIds: string[];
	syncStartTime: Date;
	syncEndTime: Date;
	isSyncComplete: boolean;
};

export function useMarketingPlatformKlaviyoDetails(id: string | number) {
	const { account } = useMarketingPlatformAccount(id);
	const { get } = useAuthenticatedFetch() as Get<KlaviyoConnectionDetails>;
	const { data: klaviyoDetails, isFetching: isKlaviyoDetailsLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_KLAVIYO_DETAILS, id),
		queryFn: () => get(`/marketing-platform-accounts/${id}/klaviyo-details`),
		enabled: account?.klaviyoConnected
	});
	return { klaviyoDetails, isKlaviyoDetailsLoading };
}

export type FacebookPage = {
	id: string;
	enabled: boolean;
	tokenPresent: boolean;
	numImports: number;
	tags?: string[];
	forms: FacebookForm[];
	createdAt: Date;
	updatedAt: Date;
};

export type FacebookForm = {
	id: number;
	formId: string;
	numImports: number;
	tags?: string[];
	testPassed: boolean;
	testedAt: Date;
	testPassedAt: Date;
	createdAt: Date;
	updatedAt: Date;
};

export function useMarketingPlatformFacebookDetails(id: number | string) {
	const { account } = useMarketingPlatformAccount(id);
	const { get } = useAuthenticatedFetch() as Get<FacebookPage[]>;
	const { data, isFetching: isFacebookPagesLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_FACEBOOK_DETAILS, id),
		select: useDateTransformerFactory('testedAt', 'testPassedAt'),
		queryFn: () => get(`/marketing-platform-accounts/${id}/facebook-details`),
		enabled: Boolean(account?.facebookExternalId)
	});
	return { facebookPages: data ?? [], isFacebookPagesLoading };
}

export type ConnectionDescription = {
	id: ConnectionType;
	name: string;
	connected: boolean;
	hasDetails?: boolean;
};

export function useMarketingPlatformConnectionList(id: string | number) {
	const { account } = useMarketingPlatformAccount(id);

	if (!account) {
		return;
	}

	const {
		instagramConnected,
		facebookConnected,
		facebookExternalId,
		mailChimpConnected,
		klaviyoConnected,
		postscriptConnected,
		shopifyReviewServiceType
	} = account;
	return [
		{
			id: 'MAILCHIMP',
			name: 'Mailchimp',
			connected: mailChimpConnected,
			hasDetails: mailChimpConnected
		} as ConnectionDescription,
		{
			id: 'KLAVIYO',
			name: 'Klaviyo',
			connected: klaviyoConnected,
			hasDetails: klaviyoConnected
		} as ConnectionDescription,
		{
			id: 'FACEBOOK',
			name: 'Facebook leads',
			connected: facebookConnected,
			hasDetails: facebookExternalId
		} as ConnectionDescription,
		{
			id: 'INSTAGRAM',
			name: 'Instagram',
			connected: instagramConnected
		} as ConnectionDescription,
		{
			id: 'POSTSCRIPT',
			name: 'Postscript',
			connected: postscriptConnected
		} as ConnectionDescription,
		{
			id: 'REVIEW_SERVICE',
			name: 'Review service',
			connected: Boolean(shopifyReviewServiceType)
		} as ConnectionDescription
	];
}

export type ManualJobType = 'SUPPRESSED_EMAILS_IMPORT';

export type ManualJobStatus = 'CREATED' | 'RUNNING' | 'CANCELLING' | 'CANCELLED' | 'FAILED' | 'COMPLETED';

export type ManualJob = {
	id: number;
	accountId: number;
	jobType: ManualJobType;
	jobStatus: ManualJobStatus;
	percentComplete: number;
	hasInput: boolean;
	hasOutput: boolean;
	startTime?: Date;
	endTime?: Date;
	createdAt: Date;
	updatedAt: Date;
};

export type ManualJobDetails = {
	jobInput?: string;
	jobOutput?: string;
};

export function useMarketingPlatformManualJobs(id: string | number) {
	const { get } = useAuthenticatedFetch() as Get<ManualJob[]>;

	const {
		data,
		isFetching: isLoading,
		refetch
	} = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS, id),
		queryFn: () => get(`/marketing-platform-accounts/${id}/manual-jobs`),
		select: useDateTransformerFactory('startTime', 'endTime')
	});

	return {
		manualJobs: data ?? [],
		isLoading,
		refetch
	};
}

export function useMarketingPlatformManualJobDetails(manualJob?: ManualJob) {
	const { get } = useAuthenticatedFetch() as Get<ManualJobDetails>;

	const { data: manualJobDetails, isFetching: isLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_MANUAL_JOB_DETAILS, manualJob?.accountId, manualJob?.id),
		queryFn: () => get(`/marketing-platform-accounts/${manualJob?.accountId}/manual-jobs/${manualJob?.id}/details`),
		enabled: Boolean(manualJob)
	});

	return {
		manualJobDetails,
		isLoading
	};
}

const MARKETING_PLATFORM_MANUAL_JOBS_LIMIT = 10;

export function useAllMarketingPlatformManualJobs() {
	const { get } = useAuthenticatedFetch() as Get<ManualJob[]>;

	const { data, refetch, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
		queryKey: queryKey(MARKETING_PLATFORM_MANUAL_JOBS),
		queryFn: ({ pageParam = 0 }) => get(`/marketing-platform-accounts/manual-jobs?page=${pageParam}`),
		initialPageParam: 0,
		getNextPageParam: (lastPage, pages) => (lastPage.length === MARKETING_PLATFORM_MANUAL_JOBS_LIMIT ? pages.length : undefined),
		select: useDateTransformerFactory('startTime', 'endTime')
	});

	return {
		manualJobs: data?.pages ?? [],
		isLoading: isFetching,
		refetch,
		hasNextPage,
		fetchNextPage
	};
}

export function useCreateMarketingPlatformManualJob(accountId?: string | number) {
	const queryClient = useQueryClient();
	const { post } = useAuthenticatedFetch() as Post<FormData, ManualJob>;
	const [data, setData] = useState<ManualJob | undefined>();

	const transform = useDateTransformerFactory('startTime', 'endTime');

	const {
		mutate: createManualJob,
		isPending: isLoading,
		reset
	} = useMutation({
		mutationFn: ({ jobType, file }: { jobType: ManualJobType; file: File }) => {
			const body = new FormData();
			body.append('file', file);
			return post(`/marketing-platform-accounts/${accountId}/manual-jobs/${jobType}`, body);
		},
		onSuccess: (data: ManualJob) => {
			setData(transform(data) as ManualJob | undefined);
			queryClient.invalidateQueries({ queryKey: queryKey(MARKETING_PLATFORM_MANUAL_JOBS) });
			queryClient.invalidateQueries({ queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS, accountId) });
		}
	});

	const performReset = useCallback(() => {
		setData(undefined);
		reset();
	}, [reset]);

	return {
		createManualJob,
		createdJob: data,
		reset: performReset,
		isLoading
	};
}

export function useCancelMarketingPlatformManualJob() {
	const queryClient = useQueryClient();
	const { delete: del } = useAuthenticatedFetch() as Delete<ManualJob>;

	const { mutate: cancelManualJob, isPending: isLoading } = useMutation({
		mutationFn: ({ accountId, jobId }: { accountId: number; jobId: number }) =>
			del(`/marketing-platform-accounts/${accountId}/manual-jobs/${jobId}`),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: queryKey(MARKETING_PLATFORM_MANUAL_JOBS) });
			queryClient.invalidateQueries({
				queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_MANUAL_JOBS),
				exact: false
			});
		}
	});

	return {
		cancelManualJob,
		isLoading
	};
}

export type SuppressionTag = {
	tag: string;
	total: number;
};

export function useMarketingPlatformAccountSuppressionTags(accountId: string | number) {
	const { get } = useAuthenticatedFetch() as Get<SuppressionTag[]>;
	const { data, isFetching: isLoading } = useQuery({
		queryKey: queryKey(MARKETING_PLATFORM_ACCOUNT_SUPPRESSION_TAGS, accountId),
		queryFn: () => get(`/marketing-platform-accounts/${accountId}/suppression-tags`)
	});
	return {
		suppressionTags: data ?? [],
		isLoading
	};
}
