import { Card, FormatPercent, Heading, Stack } from '@segunosoftware/equinox';
import { InlineStack, Select, SkeletonBodyText } from '@shopify/polaris';
import { CurrencyCode } from '@shopify/react-i18n';
import type { MarketingPlatformAccount } from '../../hooks/marketingplatform/useMarketingPlatformAccount';
import { type ConversionRevenue, type SendingStatistics } from '../../hooks/marketingplatform/useMarketingPlatformAccountSendingStatistics';
import { renderDate } from '../../utils/dateUtils';
import ConversionFormatMoney from '../ConversionFormatMoney';

export type SendingStatisticsProps = {
	sendingStatistics: SendingStatistics;
	account: MarketingPlatformAccount;
	automationType: string;
	onChangeAutomationType: (type: string) => void;
};

const automationTypeOptions = [
	{ label: 'Abandoned checkout', value: 'ABANDONED_CHECKOUT' },
	{ label: 'Coming soon', value: 'COMING_SOON' },
	{ label: 'Discount reminder', value: 'DISCOUNT_REMINDER' },
	{ label: 'Lapsed purchaser', value: 'LAPSED_PURCHASER' },
	{ label: 'New purchaser', value: 'FIRST_PURCHASER' },
	{ label: 'Repeat purchaser', value: 'REPEAT_PURCHASER' },
	{ label: 'Welcome', value: 'WELCOME' }
];

export default function MarketingPlatformSendingStatistics({
	sendingStatistics,
	account,
	automationType,
	onChangeAutomationType
}: SendingStatisticsProps) {
	function formatConversionRevenue(conversionRevenue: ConversionRevenue) {
		return (
			<InlineStack wrap={false}>
				{conversionRevenue.conversions}/
				<ConversionFormatMoney amount={conversionRevenue.revenue} code={account.currency as CurrencyCode} />
			</InlineStack>
		);
	}

	return (
		<>
			{!sendingStatistics && <SkeletonBodyText lines={3} />}
			{sendingStatistics && (
				<>
					<Card sectioned>
						<Card.Section fullWidth flush>
							<Stack spacing="none" vertical>
								<div>
									<strong>Total automations enabled</strong>: {sendingStatistics.totalAutomationsEnabled}
								</div>
							</Stack>
						</Card.Section>
						<Card.Section fullWidth>
							<Stack spacing="none" vertical>
								<div>
									<strong>Total newsletter sends</strong>: {sendingStatistics.totalNewsletterSends}
								</div>
								<div>
									<strong>Total newsletter sends (last 30 days)</strong>: {sendingStatistics.totalNewsletterSendsLast30Days}
								</div>
								<div>
									<strong>Total newsletter sends (last 365 days)</strong>: {sendingStatistics.totalNewsletterSendsLast365Days}
								</div>
								<div>
									<strong>First newsletter send date</strong>: {renderDate(sendingStatistics.firstNewsletterSendDate)}
								</div>
								<div>
									<strong>Last newsletter send date</strong>: {renderDate(sendingStatistics.lastNewsletterSendDate)}
								</div>
							</Stack>
						</Card.Section>
						<Card.Section fullWidth flush>
							<Stack spacing="none" vertical>
								<div>
									<strong>Average newsletter sends (per day)</strong>: {sendingStatistics.avgNewsletterSendsPerDay}
								</div>
								<div>
									<strong>Average newsletter sends (per week)</strong>: {sendingStatistics.avgNewsletterSendsPerWeek}
								</div>
								<div>
									<strong>Average newsletter sends (per month)</strong>: {sendingStatistics.avgNewsletterSendsPerMonth}
								</div>
							</Stack>
						</Card.Section>

						<Card.Section fullWidth>
							<Stack spacing="none" vertical>
								<InlineStack>
									<strong>Total conversions/revenue</strong>: {formatConversionRevenue(sendingStatistics.totalConversionRevenue)}
								</InlineStack>
								<InlineStack>
									<strong>Total conversions/revenue (last 30 days)</strong>:{' '}
									{formatConversionRevenue(sendingStatistics.totalConversionRevenueLast30Days)}
								</InlineStack>
								<InlineStack>
									<strong>Total conversions/revenue (last 365 days)</strong>:{' '}
									{formatConversionRevenue(sendingStatistics.totalConversionRevenueLast365Days)}
								</InlineStack>
								<InlineStack>
									<strong>Average conversions/revenue</strong>: {formatConversionRevenue(sendingStatistics.avgConversionRevenuePerSend)}
								</InlineStack>
							</Stack>
						</Card.Section>
					</Card>
					<Card sectioned title="Newsletters">
						<Card.Section fullWidth flush>
							<Stack spacing="none" vertical>
								<div>
									<strong>Average delivery rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterDeliveryRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average delivery rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterDeliveryRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average open rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterOpenRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average open rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterOpenRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average click rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterClickRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average click rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterClickRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average bounce rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterBounceRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average bounce rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterBounceRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average spam rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterSpamRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average spam rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterSpamRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average unsubscribe rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterUnsubscribeRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average unsubscribe rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgNewsletterUnsubscribeRateLast30Days * 100} decimals={2} />
								</div>
							</Stack>
						</Card.Section>
					</Card>
					<Card
						title={
							<Stack alignment="center">
								<Stack.Item fill>
									<Heading>Automations</Heading>
								</Stack.Item>
								<Select label="Type" labelInline options={automationTypeOptions} onChange={onChangeAutomationType} value={automationType} />
							</Stack>
						}
						sectioned>
						<Card.Section fullWidth flush>
							<Stack spacing="none" vertical>
								<div>
									<strong>Average delivery rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationDeliveryRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average delivery rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationDeliveryRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average open rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationOpenRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average open rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationOpenRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average click rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationClickRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average click rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationClickRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average bounce rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationBounceRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average bounce rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationBounceRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average spam rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationSpamRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average spam rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationSpamRateLast30Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average unsubscribe rate (last 7 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationUnsubscribeRateLast7Days * 100} decimals={2} />
								</div>
								<div>
									<strong>Average unsubscribe rate (last 30 days)</strong>:{' '}
									<FormatPercent value={sendingStatistics.avgAutomationUnsubscribeRateLast30Days * 100} decimals={2} />
								</div>
							</Stack>
						</Card.Section>
					</Card>
				</>
			)}
		</>
	);
}
